<template lang="">
  <b-card header="Import Prayers">
    <b-form-group
      label="Import File"
      label-for="file"
    >
      <b-form-file
        id="file"
        v-model="prayers.salwat"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />
    </b-form-group>
    <div class="text-right mb-1 mb-md-0">
      <b-button
        :disabled="!(prayers.salwat&&prayers.entityId)"
        size="md"
        class="d-block d-md-inline-block"
        variant="primary"
        @click="importPrayers"
      >
        Import
      </b-button>
    </div>
    <div>
      <b-button
        variant="primary"
        href="https://newstage.muslimdo.com/images/activities/public/azan-assets/azan.xlsx"
      >
        Download Excel Template
      </b-button>
    </div>
  </b-card>
</template>
<script>
import handleFormData from '@/common/compositions/common/handleFormData'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ImportPrayers',
  data() {
    return {
      prayers: {
        salwat: null,
        entityId: this.$store.getters['mainEntity/getEntityId'],
      },
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()
    const { successfulOperationAlert } = handleAlerts()

    return {
      formData,
      setFormData,
      successfulOperationAlert,

    }
  },
  methods: {
    importPrayers() {
      this.setFormData(this.prayers)
      this.$portalUsers.post(`/internalops/entity/${this.$store.getters['mainEntity/getEntityId']}/prayer/import`, this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.successfulOperationAlert('Prayers is imported successfully')
      })
    },
  },
}
</script>
<style lang="">

</style>
